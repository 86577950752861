// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { connectHighlight, } from 'react-instantsearch-dom';

type StyledHighlightPropsType = {
  hit: Object,
  attribute: string,
  highlight: Object => Array<Object>,
  highlightedColor: string,
};

function HighlightComponent(props: StyledHighlightPropsType) {
  const { highlight, attribute, hit, highlightedColor, } = props;
  const { css, } = useFela();

  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span>
      {parsedHit.map((part, index) => {
        const Tag = part?.isHighlighted ? 'mark' : 'span';
        const hitStyle = css(
          part?.isHighlighted
            ? { fontWeight: 700, backgroundColor: highlightedColor, color: 'inherit', }
            : { fontWeight: 400, }
        );

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Tag className={hitStyle} key={index}>
            {part?.value}
          </Tag>
        );
      })}
    </span>
  );
}

const StyledHighlight = connectHighlight(HighlightComponent);
export default StyledHighlight;
