// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';

import SearchBoxHeyday from '../../Search/SearchBoxHeyday';
import { recipesConfiguration, } from '../searchBoxConfigs';
import HeydayConfig from '../HeydayConfig';
import { type SuggestionSelected, } from '../AutoComplete/autocompleteTypes';

type PropsType = {
  affId: string,
  domain: string,
  recipesSearchResultPage: string,
  queryMinLength: ?number,
  onSuggestionSelected: string => void,
  defaultQuery: ?string,
  multiSection: Boolean,
};

const formMiscStyles = ({ theme, }) => ({
  gridArea: 'se',
  flexDirection: 'row-reverse',
  backgroundColor: theme.color('white'),
  margin: [ { until: 's', value: '0 2rem', }, { from: 's', value: '0 4rem', }, ],
  type: [ { until: 's', value: 1, }, { from: 's', value: 2, }, ],
});

const autocompleteContainerMiscStyles = ({ theme, }) => ({
  flexGrow: 1,
});

const inputMiscStyles = ({ theme, }) => ({
  color: theme.color('primary'),
  outline: 'none',
  width: '100%',
  height: '100%',
  paddingInlineStart: '3rem',
  ':placeholder': {
    color: theme.color('primary'),
  },
});

const suggestionsContainerMiscStyles = ({ theme, }) => ({
  color: theme.color('white'),
  position: 'absolute',
  left: '0',
  right: '0',
  top: '100%',
  width: '100%',
  backgroundColor: theme.color('primary', '+1'),
  zIndex: theme.getZIndex('above', 1),
  type: [ { until: 's', value: 0, }, { from: 's', value: 1, }, ],
});

const buttonMiscStyles = ({ theme, }) => ({
  flexGrow: 0,
  marginInlineStart: 'auto',
  color: theme.color('primary'),
  paddingTop: [ { until: 's', value: '1rem', }, { from: 's', value: '1.5rem', }, ],
  paddingBottom: [ { until: 's', value: '1rem', }, { from: 's', value: '1.5rem', }, ],
  paddingInlineStart: [ { until: 's', value: '1rem', }, { from: 's', value: '1rem', }, ],
  paddingInlineEnd: [ { until: 's', value: '1rem', }, { from: 's', value: '1rem', }, ],
});

export default function RecipesSearchBox({
  affId,
  domain,
  recipesSearchResultPage,
  onSuggestionSelected,
  defaultQuery,
  queryMinLength,
  multiSection,
}: PropsType) {
  const { theme, } = useFela();

  const suggestionSelectedHandler = (
    event: SyntheticEvent<HTMLElement>,
    data: SuggestionSelected
  ) => {
    event.preventDefault();
    const query = data?.suggestionValue;
    typeof onSuggestionSelected === 'function' && onSuggestionSelected(query);
  };

  const submitHandler = (event: SyntheticEvent<HTMLElement>, query: ?string) => {
    event.preventDefault();
    query && typeof onSuggestionSelected === 'function' && onSuggestionSelected(query);
  };

  return (
    <SearchBoxHeyday
      name="q"
      defaultQuery={defaultQuery}
      affId={affId}
      domain={domain}
      action={recipesSearchResultPage}
      multiSection={multiSection}
      inputPlaceholder={theme.recipesSearchI18n.placeHolder}
      miscStyles={formMiscStyles({ theme, })}
      inputMiscStyles={inputMiscStyles({ theme, })}
      autocompleteContainerMiscStyles={autocompleteContainerMiscStyles({ theme, })}
      suggestionsContainerMiscStyles={suggestionsContainerMiscStyles({ theme, })}
      buttonMiscStyles={buttonMiscStyles({ theme, })}
      buttonSize={[ { until: 's', value: 4, }, { from: 's', value: 5, }, ]}
      onSuggestionSelected={suggestionSelectedHandler}
      queryMinLength={typeof queryMinLength !== 'undefined' ? queryMinLength : HeydayConfig.autocompleteMinCharacters}
      indexConfiguration={recipesConfiguration}
      onSubmit={submitHandler}
    />
  );
}
