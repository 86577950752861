// @flow
import * as React from 'react';
import { SHUSHU_LIST_QUERY, } from '@haaretz/graphql';
import ShushuGamesView from './ShushuGamesView.js';
import ListDataGetter from '../../ListDataGetter';
import type { ListDataType, } from '../../../../flowTypes/ListDataType';


export default function ShushuGames(props: ListDataType) {
  return (
    <ListDataGetter query={SHUSHU_LIST_QUERY} view="ShushuGames" {...props}>
      {dataProps => <ShushuGamesView {...dataProps} />}
    </ListDataGetter>
  );
}
