// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { borderTop, } from '@haaretz/htz-css-tools';

type PropsType = {
  title: string,
};

const wrapperRule = ({ theme, }) => ({
  paddingInlineStart: '3rem',
  paddingInlineEnd: '3rem',
});

const titleRule = ({ theme, }) => ({
  paddingBottom: '1rem',
  fontWeight: 700,
  extend: [
    borderTop('2px', 2, 'solid', theme.color('primary', '-1')),
    theme.type(-1),
  ],
});

function AutoCompleteSectionTitle({ title, }: PropsType) {
  const { css, } = useFela();
  return (
    <div className={css(wrapperRule)}>
      <div className={css(titleRule)}>{title}</div>
    </div>
  );
}

export default AutoCompleteSectionTitle;
