/* global fetch */
import * as React from 'react';
import { useEventTracker, } from '../../utils/EventTracker';

async function search(affiliateId, domain, query, categories, page = 1) {
  if (!query) {
    return { res: 0, r: [], };
  }
  const requestJson = {
    affId: affiliateId,
    h: domain,
    q: query.trim(),
    p: page,
    ...categories ? { filters: { categories: [ categories, ], }, } : {},
  };

  return heydaySearch(requestJson, '/search/s/');
}

async function heydaySearch(requestJson, uri) {
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestJson),
  };

  try {
    const response = await fetch(`https://heyday.io${uri}`, options);
    if (response.ok) {
      const data = await response.json();
      if (data) {
        return data;
      }
    }
  }
  catch {
    console.warn('Heyday search error');
  }
  return { res: 0, r: [], error: 'Heyday search error', };
}

export default function HeydayQuery({
  children,
  affiliateId,
  domain,
  query,
  categories,
}) {
  const [ pageNumber, setPageNumber, ] = React.useState(1);
  const [ isReady, setIsReady, ] = React.useState(false);
  const [ resultsData, setResultsData, ] = React.useState({ results: [], total: 0, error: null, firstQueryFinished: false, });
  const { biAction, } = useEventTracker();

  const loadMore = () => {
    biAction && biAction({
      actionCode: 148,
      feature: 'More results',
      featureType: 'Content',
      campaignName: categories && categories === 'recipe' ? 'Food' : 'General',
      pageType: 'Search',
    });

    search(affiliateId, domain, query, categories, pageNumber + 1)
      .then(
        data => {
          setResultsData(prevState => {
            const { results, total, error, firstQueryFinished, } = prevState;
            return { results: results.concat(data.r), total, error, firstQueryFinished, };
          });
          setPageNumber(pageNumber + 1);
        });
  };

  React.useEffect(() => {
    setIsReady(true);
    search(affiliateId, domain, query, categories).then(data => {
      setResultsData({ results: data.r, total: data.res, error: data.error, firstQueryFinished: true, });
    });
  }, [ affiliateId, domain, query, categories, ]);

  const hasMore = resultsData && resultsData.total && resultsData.results && resultsData.results.length < resultsData.total;

  return isReady && resultsData ? children(resultsData.results, hasMore, loadMore, resultsData.firstQueryFinished, resultsData.total, resultsData.error) : null;
}
