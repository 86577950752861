import HeydayConfig from './HeydayConfig';

function Mapper({ hits, mapper, mapperObject, children, }) {
  const jsonHits = hits
    .map(item => ({
      ...item.pd,
      paragraph: Array.isArray(item.wd) && item.wd.length > 0
        ? enhanceDescriptionArray(item.wd, item.wd.length > 1
          ? HeydayConfig.maxWordsTwoParagraphs
          : HeydayConfig.maxWordsOneParagraph)
        : item.pd?.description && item.pd.description.length > 0
          ? enhanceDescriptionString(item.pd.description, HeydayConfig.maxWordsOneParagraph)
          : null,
      highlight: Array.isArray(item.hqd) && item.hqd.length > 0 ? item.hqd : null,
    }))
    .map(item => mapper(item, mapperObject));
  return children(jsonHits);
}

function enhanceDescriptionString(paragraph, maxWords) {
  if (typeof paragraph !== 'string') {
    return paragraph;
  }
  const paragraphArray = paragraph.trim().split(' ');
  const wordCount = paragraphArray.length;
  if (wordCount <= maxWords) {
    return paragraph;
  }
  return `${reduceWordCountFromEnd(paragraph, maxWords)} ...`;
}


function enhanceDescriptionArray(description, maxWords) {
  if (!Array.isArray(description)) {
    return description;
  }
  return description.map((paragraph, index) => {
    if (index === 0) {
      return `... ${reduceWordCountFromSides(paragraph, maxWords)} ... `;
    } if (index === 1) {
      return `${reduceWordCountFromSides(paragraph, maxWords)} ...`;
    }
    return null;
  });
}

function reduceWordCountFromEnd(paragraph, maxWords) {
  if (!paragraph) {
    return paragraph;
  }
  const paragraphArray = paragraph.trim().split(' ');
  const wordCount = paragraphArray.length;
  if (wordCount <= maxWords) {
    return paragraph;
  }
  return paragraphArray.filter((word, index) => index < maxWords).join(' ');
}

function reduceWordCountFromSides(paragraph, maxWords) {
  if (!paragraph) {
    return paragraph;
  }

  const paragraphArray = paragraph.trim().split(' ');
  const wordCount = paragraphArray.length;
  if (wordCount <= maxWords) {
    return paragraph;
  }

  let cutFromStart;
  let cutFromEnd;
  const diff = wordCount - maxWords;
  const cut = diff / 2;
  if (diff % 2 === 0) { // odd
    cutFromStart = diff / 2;
    cutFromEnd = cutFromStart;
  }
  else { // even
    cutFromStart = Math.floor(cut);
    cutFromEnd = cutFromStart + 1;
  }

  return paragraphArray.filter((word, index) => index >= cutFromStart && index < wordCount - cutFromEnd).join(' ');
}

export default Mapper;
