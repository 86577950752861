// @flow
import * as React from 'react';
import { useFela, } from 'react-fela';
import { useInView, } from 'react-intersection-observer';
import HtzLink from '../../../HtzLink/HtzLink';
import Image from '../../../Image/Image';
import Teaser from '../../../Teaser/NewTeaser';
import TeaserMedia from '../../../TeaserMedia/NewTeaserMedia';
import TeaserHeader from '../../../TeaserHeader/TeaserHeader';

import getImageAssets from '../../../../utils/getImageAssets';
import { useEventTracker, } from '../../../../utils/EventTracker';
import type { TeaserDataType, } from '../../../../flowTypes/TeaserDataType';

type PropsType = {
    item: TeaserDataType,
    biAction: ?() => void,
    isAnimated?: boolean,
};


const campaignDetailsMap = {
  '/riddles/ty-page/haaretz-wordle': 'to wordle',
  '/riddles/sudoku': 'to sudoku',
  'ty-article-20q': 'to 20 questions',
  '00000184-9eae-d199-ade4-dfef359e0000': 'to picsle',
  '0000018d-9705-d57f-a1ef-bf1d5b3d0000': 'to connections',
  '0000018b-7abc-d1da-a1bb-7fbcccb00000': 'to quotes',
};
const campaignKeys = Object.keys(campaignDetailsMap);

function generateBiData(item) {
  const keyFound = campaignKeys.find(key => item.path.includes(key));

  return {
    featureType: 'Content',
    feature: 'riddles list',
    campaignName: 'riddles section page',
    campaignDetails: keyFound ? campaignDetailsMap[keyFound] : 'unknown',
    actionCode: 109,
  };
}

function teaserMiscStyle({ theme, inView, }) {
  return {
    ...(inView
      ? {
        opacity: 1,
        transform: 'translateY(0)',
      }
      : {
        opacity: 0.05,
        transform: 'translateY(25%)',
      }),
    transition: 'all 0.5s linear',
    gridTemplateColumns: 'auto',
    gridTemplateRows: '3rem 32rem auto',
  };
}


ShushuGamesTeaser.defaultProps = {
  isAnimated: false,
};

const areasTemplate = ' "." "media" "content"';


export default function ShushuGamesTeaser({ item, isAnimated, }: PropsType) {
  const { theme, css, } = useFela();
  const { biAction, } = useEventTracker();

  const [ ref, inView, ] = useInView({ triggerOnce: true, threshold: 0.25, });
  const image = item?.mobileImage || item?.image;
  const biData = generateBiData(item);

  return (
    <Teaser
      areasTemplate={areasTemplate}
      attrs={{ id: `shushu-games-${item.contentId}`, }}
      miscStyles={teaserMiscStyle({ theme, inView, })}
      fwRef={ref}
    >
      <TeaserMedia
        data={item}
        onClick={() => biAction(
          biData
        )}
      >
        <div className={css({
          position: 'absolute',
          width: '25rem',
          top: '50%',
          insetInlineEnd: '50%',
          transform: 'translate(-50%, -50%)',
        })}
        >
          <Image
            image={image}
            miscStyles={{
              backgroundColor: 'transparent',
            }}
            imgOptions={getImageAssets({
              bps: theme.bps,
              aspect: 'square',
              sizes: [ { until: 's', size: '100vw', }, ],
              widths: [ 1200, 800, 600, 425, 375, ],
            })}
          />
        </div>

      </TeaserMedia>

      <TeaserHeader
        isGridItem
        isCentered
        onClick={() => biAction(
          biData
        )}
        wrapperMiscStyles={{
          marginTop: '3rem',
          marginBottom: '4rem',
          paddingRight: '4rem',
          paddingLeft: '4rem',
        }}
        miscStyles={{
          fontWeight: 600,
        }}
        typeScale={[ { until: 'xl', value: 2, }, ]}
        {...item}
      />
    </Teaser>
  );
}
