import dynamic from 'next/dynamic';

import * as React from 'react';
import {
  AlgoliaList,
  AlgoliaInstantSearchUrl,
  HeydayInstantSearch,
  BreakingNewsBox,
  Abner,
  Aharoni,
  Beavis,
  Bender,
  Bilingual,
  Boxy,
  BoxyInverse,
  BoxyInverseLabels,
  Broadcast1,
  Broadcast2,
  Broadcast3,
  Butthead,
  Calculon,
  // Clampazzo,
  Clamps,
  Crushinator,
  ClickTrackerWrapper,
  Conrad,
  Corona,
  CoronaHomeStory,
  // Donatello,
  Donbot,
  // ElementGroup,
  Error,
  Events,
  HtmlNode,
  Embed,
  HtzFooter,
  Flexo,
  Gamal,
  AdSlot,
  AdSlotPrioritySelector,
  GridContainer,
  GridElement,
  Hawking,
  HtmlElement,
  HyperChicken,
  // Iframe,
  Interactive,
  // Kroker,
  Leonardo,
  List,
  MainBlock,
  Marco,
  Masthead,
  // Michelangelo,
  MobileListWrapper,
  // Mom,
  Morbo,
  // Mousepad,
  NewsletterServiceMessage,
  OutbrainSectionPage,
  Muli,
  // Nibbler,
  Nils,
  // OutbrainHomepage,
  Ogi,
  Panucci,
  Paragraph,
  Pazuzu,
  Kariv,
  PazuzuLabels,
  Roberto,
  Elzar,
  RssFeed,
  Santa,
  SantaLabels,
  // Slim,
  Schoonhoven,
  Shushu,
  ShushuGames,
  Slugs,
  Spawn,
  Tate,
  TateLabels,
  // TabElement,
  TopNews,
  Vogel,
  // Weekend,
  WidePageClickTracker,
  WidePageLayoutImage,
  Wong,
  Zapp,
  ZappLabels,
  // Zombie,
  LayoutContainer,
  SectionPageTitle,
  SectionArticle,

  // icons
  IconHaaretzLogo,
  IconAlefLogoTransparent,
  IconHtzLoader,
  IconHaaretzLabel,

  // utils
  getElementsFactory,

} from '@haaretz/htz-components';

const UserRecommendationsUnsubPage = dynamic(
  () => import('../components/UserRecommendationsUnsubPage/UserRecommendationsUnsubPage')
);

const ImageGalleryTeaser = dynamic(() => import('../components/ImageGalleryTeaser/ImageGalleryteaser'));

function MastheadLogo() {
  return (
    <IconHaaretzLogo
      size={3.5}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

// eslint-disable-next-line react/prop-types
function SectionPageBreakingNews({ properties, ...props }) {
  return (
    <LayoutContainer miscStyles={{ paddingBottom: [ { from: 's', value: '4rem', }, ], }}>
      <BreakingNewsBox {...properties} {...props} />
    </LayoutContainer>
  );
}

// eslint-disable-next-line react/prop-types
function MastheadLabelLogo() {
  return (
    <IconHaaretzLabel
      attrs={{ 'aria-hidden': true, }}
      size={5}
      fill={[ 'bodyText', 'base', ]}
      miscStyles={{
        display: 'block',
      }}
    />
  );
}

const elements = new Map([
  // General components
  [ 'AlgoliaInstantSearch', AlgoliaInstantSearchUrl, ],
  [ 'HeydayInstantSearch', HeydayInstantSearch, ],
  [ 'SearchResultsList', AlgoliaList, ],
  // [ 'com.tm.layout.element.BreakingNewsListElement', SectionPageBreakingNews, ],
  [ 'PageNavigation', Masthead, ],
  [ 'MainBlock', MainBlock, ],
  [ 'ClickTrackerWrapper', ClickTrackerWrapper, ],
  [ 'widePageClickTrackerBannersWrapper', WidePageClickTracker, ],
  [ 'AdSlot', AdSlot, ],
  [ 'AdSlotPrioritySelector', AdSlotPrioritySelector, ],
  [ 'AdSlotRichTextElement', AdSlot, ],
  [ 'Outbrain', OutbrainSectionPage, ],
  // [ 'com.tm.ElementGroup', ElementGroup, ],
  [ 'OnlifeFeed', RssFeed, ],
  [ 'PageFooter', HtzFooter, ],
  [ 'GridItem', GridElement, ],
  [ 'Grid', GridContainer, ],
  // [ 'com.tm.HeaderNewsGroup', TopNews, ],
  [ 'HeaderNewsList', props => {
    if (props.kind === 'breakingNews') return <BreakingNewsBox {...props} />;
    return <TopNews {...props} />;
  }, ],
  [ 'topNews', TopNews, ],
  // [ 'interactive', Interactive, ],
  // [ 'com.tm.ArticleInteractiveHtmlElement', Interactive, ],
  [ 'HtmlElement', HtmlElement, ],
  [ 'StaticElement', HtmlElement, ],
  [ 'image', WidePageLayoutImage, ],
  [ 'htz_image_Image', WidePageLayoutImage, ],
  // [ 'com.tm.IframElement', Iframe, ],
  // [ 'com.tm.ResubscribeElementsGroup', ],
  // [ 'com.tm.TabViewElement', TabElement, ],
  [ 'htz_list_List', List, ],
  // [ 'com.tm.PageTitle', SectionPageTitle, ],
  // [ 'com.htz.SectionArticle', SectionArticle, ],
  [ 'UserStoryTopic', CoronaHomeStory, ],
  [ 'NewsletterPageMessage', NewsletterServiceMessage, ],
  [ 'error', Error, ],
  [ 'imageGalleryTeaser', ImageGalleryTeaser, ],
  [ 'unsubscribePageRecommendations', UserRecommendationsUnsubPage, ],
  [ 'HtmlNode', HtmlNode, ],

  // list views
  [ 'Abner', Abner, ],
  [ 'Beavis', Beavis, ],
  [ 'Bender', Bender, ],
  [ 'Boxy', props => <Boxy {...props} viewProps={{ hasMarginTop: true, }} />, ],
  [
    'BoxyInverse',
    props => (
      // eslint-disable-next-line react/prop-types
      <BoxyInverse {...props} viewProps={{ ...(props.viewProps || {}), hasMarginTop: true, }} />
    ),
  ],
  [ 'BoxyInverse_Labels', BoxyInverseLabels, ],
  [ 'Butthead', Butthead, ],
  [ 'Broadcast1', Broadcast1, ],
  [ 'Broadcast2', Broadcast2, ],
  [ 'Broadcast3', Broadcast3, ],
  [ 'Calculon', Calculon, ],
  [ 'Crushinator', Crushinator, ],
  // [ 'Clampazzo', Clampazzo, ],
  [ 'Clamps', Clamps, ],
  [ 'Conrad', Conrad, ],
  [ 'Corona', Corona, ],
  // [ 'Donatello', Donatello, ],
  [ 'Debate1', Bilingual, ],
  [ 'Debate2', Bilingual, ],
  [ 'Donbot', Donbot, ],
  [ 'Events', Events, ],
  [ 'Embed', Embed, ],
  [ 'Hawking', Hawking, ],
  [ 'HyperChicken', HyperChicken, ],
  [ 'Flexo', Flexo, ],
  [ 'Gamal', Gamal, ],
  // [ 'Kroker', Kroker, ],
  [ 'Leonardo', Leonardo, ],
  // [ 'Michelangelo', Michelangelo, ],
  // [ 'Mom', Mom, ],
  // [ 'Mousepad', Mousepad, ],
  [ 'Morbo', Morbo, ],
  // [ 'Nibbler', Nibbler, ],
  [ 'Nils', Nils, ],
  [ 'Ogi', Ogi, ],
  [ 'Muli', Muli, ],
  [ 'Shushu', Shushu, ],
  [ 'ShushuGames', ShushuGames, ],
  [ 'Panucci', Panucci, ],
  [ 'Pazuzu', Pazuzu, ],
  [ 'Pazuzu_Labels', PazuzuLabels, ],
  [ 'Roberto', Roberto, ],
  [ 'Santa', Santa, ],
  [ 'Santa_Labels', SantaLabels, ],
  // [ 'Slim', Slim, ],
  [ 'Schoonhoven', Schoonhoven, ],
  [ 'Schoonhoven_Labels', props => <Schoonhoven {...props} viewProps={{ ...(props.viewProps || {}), isCommercial: true, }} />, ],
  [ 'Tate', Tate, ],
  [ 'Tate_Labels', TateLabels, ],
  [ 'Slugs', Slugs, ],
  [ 'Spawn', Spawn, ],
  // [ 'Spawn', Spawn, ],
  // [ 'Weekend', Weekend, ],
  [ 'Wong', Wong, ],
  [ 'Zapp', Zapp, ],
  [ 'Zapp_Labels', ZappLabels, ],
  // [ 'Zombie', Zombie, ],
  [ 'Vogel', Vogel, ],
  [ 'Elzar', Elzar, ],

  // Algolia list-views
  [ 'Marco', Marco, ],
  [ 'Aharoni', Aharoni, ],
  [ 'mastheadLogo', MastheadLogo, ],
  [ 'mastheadLabelLogo', MastheadLabelLogo, ],
  [ 'siteLogo', IconHaaretzLogo, ],
  [ 'siteIconTransparent', IconAlefLogoTransparent, ],
  [ 'Kariv', Kariv, ],

  // /* SectionArticle components */
  // [ 'p', Paragraph, ],
  // [ 'h3', Paragraph, ],
  // [ 'a', Paragraph, ],
  // [ 'ul', Paragraph, ],
  // [ 'ol', Paragraph, ],
  // [ 'li', Paragraph, ],

  // icons
  [ 'loaderIcon', IconHtzLoader, ],
]);

const getSectionPageElements = getElementsFactory(elements);

export default getSectionPageElements;
