// @flow

import * as React from 'react';
import ListView from '../../../ListView/NewListView';
import type { ListViewProps, } from '../../../../flowTypes/ListViewProps';
import ShushuGamesTeaser from './ShushuGamesTeaser';

const gridTemplateColumns = [
  { until: 's', value: '1fr', },
  { from: 's', until: 'l', value: 'repeat(2,1fr)', },
  { from: 'l', value: 'repeat(3,1fr)', },
];


export default function ShushuGamesView({
  list,
  isLazyloadImages,
}: ListViewProps) {
  return (
    <ListView
      colTemplate={gridTemplateColumns}
      gridGap="4rem"
      padding={[ { until: 'xl', value: '0 4rem', }, ]}
      attrs={{
        'data-test': 'shushu-games',
      }}
    >
      {list.items.map(item => (
        <ShushuGamesTeaser
          item={item}
        />
      ))}
    </ListView>
  );
}
