// @flow

import * as React from 'react';
import { useFela, } from 'react-fela';
import { parseStyleProps, type StyleProps, } from '@haaretz/htz-css-tools';
import config from 'config';
import HeydayConfig from '../HeydayConfig';
import useGetComponent from '../../../hooks/GetComponentContext/useGetComponent';
import EventTracker from '../../../utils/EventTracker';
import LayoutContainer from '../../PageLayout/LayoutContainer';
import LayoutRow from '../../PageLayout/LayoutRow';
import NoResults from '../NoResults';
import Mapper from '../Mapper';
import HeydayQuery from '../HeydayQuery';
import teaserDataMapper, { defaultTeaserDataMapperObject as teaserMapperObject, } from '../teaserDataMapper';
import ClickArea from '../../ClickArea/ClickArea';
import useQueryParam from '../../../hooks/useQueryParam';
import RecipesSearchBox from './RecipesSearchBox';


type HeydayInstantSearchPropsType = {
  isLazyloadImages: ?boolean,
  filter: ?string,
  view: ?string,
  miscStyles: ?StyleProps,
}

function searchWrapperStyle({ theme, isSticky, searchboxBgc, searchWrapperMiscStyles, }) {
  return {
    backgroundColor: Array.isArray(searchboxBgc) && searchboxBgc.length > 1 ? theme.color(searchboxBgc[0], searchboxBgc[1]) : null,
    extend: [
      (isSticky
        ? theme.mq(
          { until: 's', },
          {
            position: 'sticky',
            top: 0,
            zIndex: theme.getZIndex('stickyListViewHeader'),
          }
        )
        : []),
      ...(searchWrapperMiscStyles
        ? parseStyleProps(searchWrapperMiscStyles, theme.mq, theme.type)
        : []),
    ],
  };
}

function HeydayInstantSearch({
  isLazyloadImages,
  filter,
  view,
  miscStyles,
}: HeydayInstantSearchPropsType) {
  const [ queryParam, setQueryParam, ] = useQueryParam('q');
  const { css, theme, } = useFela();
  const getListView = useGetComponent();
  const ListInner = getListView(view);

  const listBiAction = (listId, viewName, biAction) => ({ index, data, }) => {
    biAction({
      actionCode: 109,
      ArticleId: data.representedContent || data.contentId,
      ListId: listId,
      NoInList: index + 1,
      ViewName: viewName,
      pageType: 'Search',
      additionalInfo: {
        searchTerm: queryParam,
      },
    });
  };

  const listBiImpression = (listId, viewName, biImpression) => ({ index, data, }) => {
    biImpression({
      ArticleId: data.representedContent || data.contentId,
      ListId: listId,
      NoInList: index + 1,
      ViewName: viewName,
      pageType: 'Search',
      additionalInfo: {
        searchTerm: queryParam,
      },
    });
  };


  const searchWrapperClasses = css(searchWrapperStyle);
  const onQueryChange = newQuery => { setQueryParam(newQuery); };

  return (
    <LayoutRow namedBgc="transparent" miscStyles={miscStyles}>
      <LayoutContainer namedBgc="transparent">
        <div className={searchWrapperClasses}>
          <RecipesSearchBox
            affId={HeydayConfig.affId}
            domain={`www.${config.get('domain')},`}
            recipesSearchResultPage={HeydayConfig.recipesSearchResultPage}
            multiSection
            queryMinLength={0}
            defaultQuery={queryParam}
            onSuggestionSelected={query => {
              typeof onQueryChange === 'function' && onQueryChange(query);
            }}
          />
        </div>
        <HeydayQuery
          affiliateId={HeydayConfig.affId}
          domain={`www.${config.get('domain')}`}
          query={queryParam}
          categories={filter && filter !== 'none' ? filter : null}
        >
          {(results, hasMore, loadMore, firstQueryFinished, total, error) => (Array.isArray(results) && results.length > 0 ? (
            <>
              <Mapper hits={results} mapper={teaserDataMapper} mapperObject={teaserMapperObject}>
                { translatedResults => (
                  <EventTracker>
                    {({ biAction, biImpression, }) => (
                      <ListInner
                        list={{ items: translatedResults, title: theme.headerSearchI18n.resultsListTitle, }}
                        totalHits={total}
                        query={queryParam}
                        isHideHeader
                        isLazyloadImages={isLazyloadImages}
                        biAction={listBiAction(`SearchResults.${config.get('domain')}`, view, biAction)}
                        biImpression={listBiImpression(`SearchResults.${config.get('domain')}`, view, biImpression)}
                      />
                    )
                  }
                  </EventTracker>
                )
              }
              </Mapper>
              {hasMore ? (
                <LayoutRow namedBgc="transparent">
                  <LayoutContainer
                    bgc="transparent"
                    miscStyles={{ paddingBlockStart: '4rem', display: 'flex', justifyContent: 'center', }}
                  >
                    <ClickArea
                      miscStyles={{
                        height: '6rem',
                        width: '18rem',
                        color: theme.color('button', 'algoliaText'),
                        fontWeight: 700,
                        border: [ '1px', 1, 'solid', ],
                      }}
                      onClick={loadMore}
                    >
                      {theme.headerSearchI18n.loadMore}
                    </ClickArea>
                  </LayoutContainer>
                </LayoutRow>
              ) : null}
            </>
          ) : firstQueryFinished && Array.isArray(results) && results.length === 0 ? <NoResults error={error} /> : null)}
        </HeydayQuery>
      </LayoutContainer>
    </LayoutRow>
  );
}

HeydayInstantSearch.defaultProps = {
  isLazyloadImages: false,
  filter: null,
  view: null,
  miscStyles: null,
};

export default HeydayInstantSearch;
